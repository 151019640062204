import React from 'react';
import { useMediaQuery } from "@mui/material";

const ExtrasDetails = ({ extras, is_commission_included, platformFee, formatPrice=() => {} }) => {
  const is2KDevice = useMediaQuery("(min-width: 2000px)");

  return (
    <div className="data data-extras">
      <div className="item-extra">
        <span className="bold">Extras</span>
        <span className="bold quantity-extra-title">Quantity</span>
        <span className="bold">Amount</span>  
      </div>
      {extras?.map((extra) => {
        const optionCounts = extra.another_options?.length
          ? extra.another_options.reduce((acc, option) => {
              acc[option] = (acc[option] || 0) + 1;
              return acc;
            }, {})
          : null;

        return (
          <div className="item-extra" key={extra.extra_id}>
            <div className="extra-option-title">
              <p className="medium">{extra.name || extra.extra_title}</p>
              <span className="medium second-title">
               
                {extra.type === "booking" && extra.pricing > 0 && 
                `${(extra.pricing + ((is_commission_included === 0 || is_commission_included === "0") ? platformFee * extra.pricing: 0)).toFixed(2)} AED Per Booking`
                }
                {extra.extra_type === "booking" && extra.extra_price > 0 && 
                    `${(extra.extra_price + ((is_commission_included === 0 || is_commission_included === "0") ? platformFee * extra.extra_price : 0)).toFixed(2)} AED Per Booking`
                }
              </span>
            </div>
            <div className={`medium only-quantity quantity-sm ${is2KDevice ? 'quantity-extra' : 'ml-16'} ${!optionCounts ? 'only-quantity' : ''}`}>
              {optionCounts ? (
                Object.entries(optionCounts).map(([option, count], optionIndex) => (
                  <p className="extra-quantity-option" key={`${extra.extra_id}-${optionIndex}`}>
                    {count} x {option}
                  </p>
                ))
              ) : (
                <p className="extra-quantity-option">
                  <span className={`${!optionCounts ? 'only-quantity-perperson-price' : ''}`}>{extra.quantity}</span>
                </p>
              )}
            </div>
            {extra.pricing === 0 || extra.extra_price === 0 ? (
              <div className="per-person-price price-sm payment-summary-per-person-price">
                {optionCounts ? (
                  Object.entries(optionCounts).map(([option, count], optionIndex) => (
                    <p className="medium" key={`${extra.extra_id}-${optionIndex}`}>
                      Price included
                    </p>
                  ))
                ) : (
                  <p className="medium">Price included</p>
                )}
              </div>
            ) : (
              extra.type === "booking" || extra.extra_type === "booking" ? (
                <p className="medium price-sm">
                  {`${formatPrice((extra.pricing || extra.extra_price) + ((is_commission_included === 0 || is_commission_included === "0") ? platformFee * (extra.pricing || extra.extra_price) : 0))} AED`}
                </p>
              ) : (
                <div className="per-person-price price-sm payment-summary-per-person-price">
                  {optionCounts ? (
                    Object.entries(optionCounts).map(([option, count], optionIndex) => (
                      <p className="medium test1" key={`${extra.extra_id}-${optionIndex}`}>
                        {`${formatPrice((extra.pricing * count || ((extra.extra_price/extra.quantity) * count)) + ((is_commission_included === 0 || is_commission_included === "0") ? platformFee * (extra.pricing * count || ((extra.extra_price/extra.quantity) * count)) : 0))} AED`}
                      </p>
                    ))
                  ) : (
                    <p className="medium test2">
                      {`${formatPrice(((extra.pricing * extra.quantity) || extra.extra_price) + ((is_commission_included === 0 || is_commission_included === "0") ? platformFee * ((extra.pricing * extra.quantity) || extra.extra_price) : 0))} AED`}
                    </p>
                  )}
                </div>
              )
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ExtrasDetails;